import { default as indexsHxOylH921Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/index.vue?macro=true";
import { default as _91id_9380Kz4PtfeHMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/bundle-choices/[id].vue?macro=true";
import { default as index88pSXVhEBeMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/bundle-choices/index.vue?macro=true";
import { default as _91id_93TJd0EzWR3BMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/bundles/[id].vue?macro=true";
import { default as indexlEQmCTpMisMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/bundles/index.vue?macro=true";
import { default as _91menuCategoryId_93G4McGmoTJ6Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/menus/[id]/categories/[menuCategoryId].vue?macro=true";
import { default as indexoBmborAZDeMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/menus/[id]/index.vue?macro=true";
import { default as indexTUyzzgYLCbMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/menus/index.vue?macro=true";
import { default as indexH5P66A8R0wMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/modifiers/index.vue?macro=true";
import { default as indexHsvuku59sZMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/modifiers/modifier-groups/[id]/index.vue?macro=true";
import { default as _91modifierId_93O0xTueuM1eMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue?macro=true";
import { default as _91id_93zNaXm1lv1QMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/modifiers/modifier-groups/[id].vue?macro=true";
import { default as indexrsAJZJxomvMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/modifiers/modifier-groups/index.vue?macro=true";
import { default as indexrIenGZBJy5Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/products/index.vue?macro=true";
import { default as _91id_93mY3dGOF4oWMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/products/product-categories/[id].vue?macro=true";
import { default as indexWVSqeMwkuVMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/products/product-categories/index.vue?macro=true";
import { default as _91id_93ndeZnnvsbDMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/products/products/[id].vue?macro=true";
import { default as indexFVOitpbvkWMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/products/products/index.vue?macro=true";
import { default as merchantsfzflrRpro2Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/merchants.vue?macro=true";
import { default as _91accountId_93KBH3Wd0UvBMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/account-configurations/[id]/accounts/[accountId].vue?macro=true";
import { default as index9ayTybcqCXMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/account-configurations/[id]/index.vue?macro=true";
import { default as _91id_93d6g16Jg2VWMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/account-configurations/[id].vue?macro=true";
import { default as indexmFqVscOqaTMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/account-configurations/index.vue?macro=true";
import { default as credentialsiozQt2zJr8Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/connections/[id]/credentials.vue?macro=true";
import { default as index2RNg0aU6QnMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/connections/[id]/index.vue?macro=true";
import { default as _91id_933XkthszJFAMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/connections/[id].vue?macro=true";
import { default as _91driver_93QJuF31mv1kMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/connections/create/[driver].vue?macro=true";
import { default as indexCqlatQIiWKMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/connections/create/index.vue?macro=true";
import { default as indexyz6ysn5A5aMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/connections/index.vue?macro=true";
import { default as _91id_93vK3uJ6qXNlMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/discounts/discounts/[id].vue?macro=true";
import { default as indexjArAteXQh5Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/discounts/discounts/index.vue?macro=true";
import { default as indexaS7MUcXVZhMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/discounts/index.vue?macro=true";
import { default as indexNXXwLt8efpMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/employees/[id]/index.vue?macro=true";
import { default as log91wh3Fq5d5Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/employees/[id]/log.vue?macro=true";
import { default as _91id_93hFNOKMd37xMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/employees/[id].vue?macro=true";
import { default as indexHrlMn5ERnBMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/employees/index.vue?macro=true";
import { default as indexcjgbRfI9jvMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/images/index.vue?macro=true";
import { default as kitchensATxQjWa4YeMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/kitchens.vue?macro=true";
import { default as indexkG1Q5HE2DcMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/payments/index.vue?macro=true";
import { default as payment_45methodsH2zaFCTDsNMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/payments/payment-methods.vue?macro=true";
import { default as indexniZbIsMe0xMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/reservations/index.vue?macro=true";
import { default as indexRCRDlpCfncMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/reviews/index.vue?macro=true";
import { default as categoriese8kyHl6jjBMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stock/categories.vue?macro=true";
import { default as indexmO460NNOeEMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stock/index.vue?macro=true";
import { default as itemsbZdjw12FbSMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stock/items.vue?macro=true";
import { default as modifier_45recipesvbrON4EUFQMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stock/modifier-recipes.vue?macro=true";
import { default as _91storeId_93xSi8Ia4lCDMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stock/stores/[storeId].vue?macro=true";
import { default as variant_45recipesuBL1l7ZzpTMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stock/variant-recipes.vue?macro=true";
import { default as stockyoVl5DZwx0Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stock.vue?macro=true";
import { default as bookkeepingOv5iwiqkiZMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stores/[id]/bookkeeping.vue?macro=true";
import { default as _91floorId_93YodMjVJMtRMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stores/[id]/floors/[floorId].vue?macro=true";
import { default as indexTvIrqvXWR7Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stores/[id]/floors/index.vue?macro=true";
import { default as hardwareEaRVIFapsCMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stores/[id]/hardware.vue?macro=true";
import { default as indexPvCyU77tR3Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stores/[id]/index.vue?macro=true";
import { default as kitchenHu0AThSmpCMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stores/[id]/kitchen.vue?macro=true";
import { default as stock3zKYkY6gBaMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stores/[id]/stock.vue?macro=true";
import { default as _91id_93bHb89anlbHMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stores/[id].vue?macro=true";
import { default as index6JLGJvvy64Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stores/index.vue?macro=true";
import { default as indexJ9Jahbou6FMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/terminals/colors/index.vue?macro=true";
import { default as _91id_93ZHd51skMx8Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/terminals/folders/[id].vue?macro=true";
import { default as indexgXqv11wUJZMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/terminals/folders/index.vue?macro=true";
import { default as indexxltxSQ4wJNMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/terminals/terminals/[id]/index.vue?macro=true";
import { default as logGInMnKqVIfMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/terminals/terminals/[id]/log.vue?macro=true";
import { default as navigation6XvNMxg0URMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/terminals/terminals/[id]/navigation.vue?macro=true";
import { default as _91id_930fzWLhMHDfMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/terminals/terminals/[id].vue?macro=true";
import { default as indexDSeIGc6SSPMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/terminals/terminals/index.vue?macro=true";
import { default as indexY1eEH184DUMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/profile/index.vue?macro=true";
import { default as downloadzv4EhZAvSoMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/reports/download.vue?macro=true";
import { default as efficiencyAfJSVZ34AAMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/reports/efficiency.vue?macro=true";
import { default as indexGZ41w89vGBMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/reports/index.vue?macro=true";
import { default as productionqqbMbvH2g2Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/reports/production.vue?macro=true";
import { default as tipsC2gfOgkjKRMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/reports/tips.vue?macro=true";
import { default as index7Vhjblkm46Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settings/index.vue?macro=true";
import { default as indexybGqIyZC6oMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/cancellations/index.vue?macro=true";
import { default as reasonszTPDDNzoUvMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/cancellations/reasons.vue?macro=true";
import { default as cash_45transfers9adAfVjRA5Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/cash-transfers/cash-transfers.vue?macro=true";
import { default as _91id_93OvXoAioE8CMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/cash-transfers/categories/[id].vue?macro=true";
import { default as index4JZ5Uy5kS2Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/cash-transfers/categories/index.vue?macro=true";
import { default as indexxFAeGwq9N6Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/cash-transfers/index.vue?macro=true";
import { default as index8qbUkiob6MMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/channel-settlements/[id]/index.vue?macro=true";
import { default as logq37b0LuOOkMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/channel-settlements/[id]/log.vue?macro=true";
import { default as ordersn6RRB2rW1xMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/channel-settlements/[id]/orders.vue?macro=true";
import { default as _91channelSettlementSnapshotId_93Md31FSdNN8Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue?macro=true";
import { default as indexMAsTmiDZIPMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/channel-settlements/[id]/snapshots/index.vue?macro=true";
import { default as _91id_930azRAxAv73Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/channel-settlements/[id].vue?macro=true";
import { default as _91id_933cO8erHR8GMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/delivery-providers/[id].vue?macro=true";
import { default as indexrSc1BxWQw8Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/delivery-providers/index.vue?macro=true";
import { default as _91id_93te4QlS6wQyMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/order-providers/[id].vue?macro=true";
import { default as indexep7WiA9AfWMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/order-providers/index.vue?macro=true";
import { default as _91id_938HXWD3UV0nMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/orders/[id].vue?macro=true";
import { default as indexXfeydurjsTMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/orders/index.vue?macro=true";
import { default as bookkeepingOPMc5MJFAGMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/[id]/bookkeeping.vue?macro=true";
import { default as channel_45settlementsYlmwKNAOuiMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/[id]/channel-settlements.vue?macro=true";
import { default as index0xfyeBrAbvMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/[id]/index.vue?macro=true";
import { default as logz9PzGQ0AtSMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/[id]/log.vue?macro=true";
import { default as ordersTduZeDP7cgMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/[id]/orders.vue?macro=true";
import { default as _91reconciliationId_930YcFRQ8Qo5Meta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/[id]/reconciliations/[reconciliationId].vue?macro=true";
import { default as indexUCHbpfP5XCMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/[id]/reconciliations/index.vue?macro=true";
import { default as _91id_93qhmDD2RHHjMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/[id].vue?macro=true";
import { default as closing_45errorsHSTY4qArwIMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/closing-errors.vue?macro=true";
import { default as indexkHAmoftwhQMeta } from "/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/index.vue")
  },
  {
    name: "inventory-bundle-choices-id",
    path: "/inventory/bundle-choices/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/bundle-choices/[id].vue")
  },
  {
    name: "inventory-bundle-choices",
    path: "/inventory/bundle-choices",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/bundle-choices/index.vue")
  },
  {
    name: "inventory-bundles-id",
    path: "/inventory/bundles/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/bundles/[id].vue")
  },
  {
    name: "inventory-bundles",
    path: "/inventory/bundles",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/bundles/index.vue")
  },
  {
    name: "inventory-menus-id-categories-menuCategoryId",
    path: "/inventory/menus/:id()/categories/:menuCategoryId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/menus/[id]/categories/[menuCategoryId].vue")
  },
  {
    name: "inventory-menus-id",
    path: "/inventory/menus/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/menus/[id]/index.vue")
  },
  {
    name: "inventory-menus",
    path: "/inventory/menus",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/menus/index.vue")
  },
  {
    name: "inventory-modifiers",
    path: "/inventory/modifiers",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/modifiers/index.vue")
  },
  {
    name: _91id_93zNaXm1lv1QMeta?.name,
    path: "/inventory/modifiers/modifier-groups/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/modifiers/modifier-groups/[id].vue"),
    children: [
  {
    name: "inventory-modifiers-modifier-groups-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/modifiers/modifier-groups/[id]/index.vue")
  },
  {
    name: "inventory-modifiers-modifier-groups-id-modifiers-modifierId",
    path: "modifiers/:modifierId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue")
  }
]
  },
  {
    name: "inventory-modifiers-modifier-groups",
    path: "/inventory/modifiers/modifier-groups",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/modifiers/modifier-groups/index.vue")
  },
  {
    name: "inventory-products",
    path: "/inventory/products",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/products/index.vue")
  },
  {
    name: "inventory-products-product-categories-id",
    path: "/inventory/products/product-categories/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/products/product-categories/[id].vue")
  },
  {
    name: "inventory-products-product-categories",
    path: "/inventory/products/product-categories",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/products/product-categories/index.vue")
  },
  {
    name: "inventory-products-products-id",
    path: "/inventory/products/products/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/products/products/[id].vue")
  },
  {
    name: "inventory-products-products",
    path: "/inventory/products/products",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/inventory/products/products/index.vue")
  },
  {
    name: "merchants",
    path: "/merchants",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/merchants.vue")
  },
  {
    name: _91id_93d6g16Jg2VWMeta?.name,
    path: "/point-of-sale/account-configurations/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/account-configurations/[id].vue"),
    children: [
  {
    name: "point-of-sale-account-configurations-id-accounts-accountId",
    path: "accounts/:accountId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/account-configurations/[id]/accounts/[accountId].vue")
  },
  {
    name: "point-of-sale-account-configurations-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/account-configurations/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-account-configurations",
    path: "/point-of-sale/account-configurations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/account-configurations/index.vue")
  },
  {
    name: _91id_933XkthszJFAMeta?.name,
    path: "/point-of-sale/connections/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/connections/[id].vue"),
    children: [
  {
    name: "point-of-sale-connections-id-credentials",
    path: "credentials",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/connections/[id]/credentials.vue")
  },
  {
    name: "point-of-sale-connections-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/connections/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-connections-create-driver",
    path: "/point-of-sale/connections/create/:driver()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/connections/create/[driver].vue")
  },
  {
    name: "point-of-sale-connections-create",
    path: "/point-of-sale/connections/create",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/connections/create/index.vue")
  },
  {
    name: "point-of-sale-connections",
    path: "/point-of-sale/connections",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/connections/index.vue")
  },
  {
    name: "point-of-sale-discounts-discounts-id",
    path: "/point-of-sale/discounts/discounts/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/discounts/discounts/[id].vue")
  },
  {
    name: "point-of-sale-discounts-discounts",
    path: "/point-of-sale/discounts/discounts",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/discounts/discounts/index.vue")
  },
  {
    name: "point-of-sale-discounts",
    path: "/point-of-sale/discounts",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/discounts/index.vue")
  },
  {
    name: _91id_93hFNOKMd37xMeta?.name,
    path: "/point-of-sale/employees/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/employees/[id].vue"),
    children: [
  {
    name: "point-of-sale-employees-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/employees/[id]/index.vue")
  },
  {
    name: "point-of-sale-employees-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/employees/[id]/log.vue")
  }
]
  },
  {
    name: "point-of-sale-employees",
    path: "/point-of-sale/employees",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/employees/index.vue")
  },
  {
    name: "point-of-sale-images",
    path: "/point-of-sale/images",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/images/index.vue")
  },
  {
    name: "point-of-sale-kitchens",
    path: "/point-of-sale/kitchens",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/kitchens.vue")
  },
  {
    name: "point-of-sale-payments",
    path: "/point-of-sale/payments",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/payments/index.vue")
  },
  {
    name: "point-of-sale-payments-payment-methods",
    path: "/point-of-sale/payments/payment-methods",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/payments/payment-methods.vue")
  },
  {
    name: "point-of-sale-reservations",
    path: "/point-of-sale/reservations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/reservations/index.vue")
  },
  {
    name: "point-of-sale-reviews",
    path: "/point-of-sale/reviews",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/reviews/index.vue")
  },
  {
    name: stockyoVl5DZwx0Meta?.name,
    path: "/point-of-sale/stock",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stock.vue"),
    children: [
  {
    name: "point-of-sale-stock-categories",
    path: "categories",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stock/categories.vue")
  },
  {
    name: "point-of-sale-stock",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stock/index.vue")
  },
  {
    name: "point-of-sale-stock-items",
    path: "items",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stock/items.vue")
  },
  {
    name: "point-of-sale-stock-modifier-recipes",
    path: "modifier-recipes",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stock/modifier-recipes.vue")
  },
  {
    name: "point-of-sale-stock-stores-storeId",
    path: "stores/:storeId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stock/stores/[storeId].vue")
  },
  {
    name: "point-of-sale-stock-variant-recipes",
    path: "variant-recipes",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stock/variant-recipes.vue")
  }
]
  },
  {
    name: _91id_93bHb89anlbHMeta?.name,
    path: "/point-of-sale/stores/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stores/[id].vue"),
    children: [
  {
    name: "point-of-sale-stores-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stores/[id]/bookkeeping.vue")
  },
  {
    name: "point-of-sale-stores-id-floors-floorId",
    path: "floors/:floorId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stores/[id]/floors/[floorId].vue")
  },
  {
    name: "point-of-sale-stores-id-floors",
    path: "floors",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stores/[id]/floors/index.vue")
  },
  {
    name: "point-of-sale-stores-id-hardware",
    path: "hardware",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stores/[id]/hardware.vue")
  },
  {
    name: "point-of-sale-stores-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stores/[id]/index.vue")
  },
  {
    name: "point-of-sale-stores-id-kitchen",
    path: "kitchen",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stores/[id]/kitchen.vue")
  },
  {
    name: "point-of-sale-stores-id-stock",
    path: "stock",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stores/[id]/stock.vue")
  }
]
  },
  {
    name: "point-of-sale-stores",
    path: "/point-of-sale/stores",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/stores/index.vue")
  },
  {
    name: "point-of-sale-terminals-colors",
    path: "/point-of-sale/terminals/colors",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/terminals/colors/index.vue")
  },
  {
    name: "point-of-sale-terminals-folders-id",
    path: "/point-of-sale/terminals/folders/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/terminals/folders/[id].vue")
  },
  {
    name: "point-of-sale-terminals-folders",
    path: "/point-of-sale/terminals/folders",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/terminals/folders/index.vue")
  },
  {
    name: _91id_930fzWLhMHDfMeta?.name,
    path: "/point-of-sale/terminals/terminals/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/terminals/terminals/[id].vue"),
    children: [
  {
    name: "point-of-sale-terminals-terminals-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/terminals/terminals/[id]/index.vue")
  },
  {
    name: "point-of-sale-terminals-terminals-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/terminals/terminals/[id]/log.vue")
  },
  {
    name: "point-of-sale-terminals-terminals-id-navigation",
    path: "navigation",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/terminals/terminals/[id]/navigation.vue")
  }
]
  },
  {
    name: "point-of-sale-terminals-terminals",
    path: "/point-of-sale/terminals/terminals",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/point-of-sale/terminals/terminals/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/profile/index.vue")
  },
  {
    name: "reports-download",
    path: "/reports/download",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/reports/download.vue")
  },
  {
    name: "reports-efficiency",
    path: "/reports/efficiency",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/reports/efficiency.vue")
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/reports/index.vue")
  },
  {
    name: "reports-production",
    path: "/reports/production",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/reports/production.vue")
  },
  {
    name: "reports-tips",
    path: "/reports/tips",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/reports/tips.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settings/index.vue")
  },
  {
    name: "settlement-cancellations",
    path: "/settlement/cancellations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/cancellations/index.vue")
  },
  {
    name: "settlement-cancellations-reasons",
    path: "/settlement/cancellations/reasons",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/cancellations/reasons.vue")
  },
  {
    name: "settlement-cash-transfers-cash-transfers",
    path: "/settlement/cash-transfers/cash-transfers",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/cash-transfers/cash-transfers.vue")
  },
  {
    name: "settlement-cash-transfers-categories-id",
    path: "/settlement/cash-transfers/categories/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/cash-transfers/categories/[id].vue")
  },
  {
    name: "settlement-cash-transfers-categories",
    path: "/settlement/cash-transfers/categories",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/cash-transfers/categories/index.vue")
  },
  {
    name: "settlement-cash-transfers",
    path: "/settlement/cash-transfers",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/cash-transfers/index.vue")
  },
  {
    name: _91id_930azRAxAv73Meta?.name,
    path: "/settlement/channel-settlements/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/channel-settlements/[id].vue"),
    children: [
  {
    name: "settlement-channel-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/channel-settlements/[id]/index.vue")
  },
  {
    name: "settlement-channel-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/channel-settlements/[id]/log.vue")
  },
  {
    name: "settlement-channel-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/channel-settlements/[id]/orders.vue")
  },
  {
    name: "settlement-channel-settlements-id-snapshots-channelSettlementSnapshotId",
    path: "snapshots/:channelSettlementSnapshotId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue")
  },
  {
    name: "settlement-channel-settlements-id-snapshots",
    path: "snapshots",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/channel-settlements/[id]/snapshots/index.vue")
  }
]
  },
  {
    name: "settlement-delivery-providers-id",
    path: "/settlement/delivery-providers/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/delivery-providers/[id].vue")
  },
  {
    name: "settlement-delivery-providers",
    path: "/settlement/delivery-providers",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/delivery-providers/index.vue")
  },
  {
    name: "settlement-order-providers-id",
    path: "/settlement/order-providers/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/order-providers/[id].vue")
  },
  {
    name: "settlement-order-providers",
    path: "/settlement/order-providers",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/order-providers/index.vue")
  },
  {
    name: "settlement-orders-id",
    path: "/settlement/orders/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/orders/[id].vue")
  },
  {
    name: "settlement-orders",
    path: "/settlement/orders",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/orders/index.vue")
  },
  {
    name: _91id_93qhmDD2RHHjMeta?.name,
    path: "/settlement/store-settlements/:id()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/[id].vue"),
    children: [
  {
    name: "settlement-store-settlements-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/[id]/bookkeeping.vue")
  },
  {
    name: "settlement-store-settlements-id-channel-settlements",
    path: "channel-settlements",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/[id]/channel-settlements.vue")
  },
  {
    name: "settlement-store-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/[id]/index.vue")
  },
  {
    name: "settlement-store-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/[id]/log.vue")
  },
  {
    name: "settlement-store-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/[id]/orders.vue")
  },
  {
    name: "settlement-store-settlements-id-reconciliations-reconciliationId",
    path: "reconciliations/:reconciliationId()",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/[id]/reconciliations/[reconciliationId].vue")
  },
  {
    name: "settlement-store-settlements-id-reconciliations",
    path: "reconciliations",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/[id]/reconciliations/index.vue")
  }
]
  },
  {
    name: "settlement-store-settlements-closing-errors",
    path: "/settlement/store-settlements/closing-errors",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/closing-errors.vue")
  },
  {
    name: "settlement-store-settlements",
    path: "/settlement/store-settlements",
    component: () => import("/var/www/sites/portal.staging.ultrafedt.dk/releases/20241213094637/pages/settlement/store-settlements/index.vue")
  }
]